import React, { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { fetchBrands, createBrand, updateBrand, deleteBrand } from '../api/brands';

const Brands = React.createContext();

const BrandsProvider = ({ children }) => {
  const { authenticated } = useAuth();

  const [isLoading, setIsLoading] = useState();
  const [brands, setBrands] = useState([]);

  const create = data => createBrand(data).then(brand => setBrands(prev => [...prev, brand]));
  const update = (code, data) => updateBrand(code, data).then(brand => setBrands(prev => prev.map(e => e.code === code ? brand : e)));
  const cancel = code => deleteBrand(code).then(() => setBrands(prev => prev.filter(e => e.code !== code)));

  useEffect(() => {
    if (authenticated && isLoading === undefined) {
      setIsLoading(true);
      fetchBrands().then(data => {
        setBrands(data);
        setIsLoading(false);
      });
    }
  }, [authenticated, isLoading, setBrands]);

  return (
    <Brands.Provider value={{ isLoading, brands, create, update, cancel }}>
      {children}
    </Brands.Provider>
  );
};

export { Brands, BrandsProvider };
