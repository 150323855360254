import React, { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { fetchCategories, createCategory, updateCategory, deleteCategory } from '../api/categories';

const Categories = React.createContext();

const CategoriesProvider = ({ children }) => {
  const { authenticated } = useAuth();

  const [isLoading, setIsLoading] = useState();
  const [categories, setCategories] = useState([]);

  const create = data => createCategory(data).then(category => setCategories(prev => [...prev, category]));
  const update = (code, data) => updateCategory(code, data).then(category => setCategories(prev => prev.map(e => e.code === code ? category : e)));
  const cancel = code => deleteCategory(code).then(() => setCategories(prev => prev.filter(e => e.code !== code)));

  useEffect(() => {
    if (authenticated && isLoading === undefined) {
      setIsLoading(true);
      fetchCategories().then(data => {
        setCategories(data);
        setIsLoading(false);
      });
    }
  }, [authenticated, isLoading]);

  return (
    <Categories.Provider value={{ isLoading, categories, create, update, cancel }}>
      {children}
    </Categories.Provider>
  );
};

export { Categories, CategoriesProvider };
