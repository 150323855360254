import axios from 'axios';
import { API_URL } from '../config';

export const fetchStockList = async () => {
  const response = await axios.get(`${API_URL}/stocks`);
  return response.data;
};

export const fetchStock = async stockId => {
  const response = await axios.get(`${API_URL}/stocks/${stockId}`);
  return response.data;
};

export const createStock = async data => {
  const response = await axios.post(`${API_URL}/stocks`, data);
  return response.data;
};

export const updateStock = async (stockId, data) => {
  const response = await axios.post(`${API_URL}/stocks/${stockId}`, data);
  return response.data;
};

export const deleteStock = async stockId => {
  const response = await axios.delete(`${API_URL}/stocks/${stockId}`);
  return response.data;
};

export const fetchStockProducts = async stockId => {
  const response = await axios.get(`${API_URL}/stocks/${stockId}/products`);
  return response.data;
};
