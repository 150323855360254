import axios from 'axios';
import { API_URL } from '../config';

export const fetchDepartments = async () => {
  const response = await axios.get(`${API_URL}/departments`);
  return response.data;
};

export const createDepartment = async data => {
  const response = await axios.post(`${API_URL}/departments`, data);
  return response.data;
};

export const updateDepartment = async (id, data) => {
  const response = await axios.post(`${API_URL}/departments/${id}`, data);
  return response.data;
};

export const deleteDepartment = async id => {
  await axios.delete(`${API_URL}/departments/${id}`);
};
