import React, { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { fetchSeasons, createSeason, updateSeason, deleteSeason } from '../api/seasons';

const Seasons = React.createContext();

const SeasonsProvider = ({ children }) => {
  const { authenticated } = useAuth();

  const [isLoading, setIsLoading] = useState();
  const [seasons, setSeasons] = useState([]);

  const create = data => createSeason(data).then(department => setSeasons(prev => [...prev, department]));
  const update = (code, data) => updateSeason(code, data).then(department => setSeasons(prev => prev.map(e => e.code === code ? department : e)));
  const cancel = code => deleteSeason(code).then(() => setSeasons(prev => prev.filter(e => e.code !== code)));

  useEffect(() => {
    if (authenticated && isLoading === undefined) {
      setIsLoading(true);
      fetchSeasons().then(data => {
        setSeasons(data);
        setIsLoading(false);
      });
    }
  }, [authenticated, isLoading]);

  return (
    <Seasons.Provider value={{ isLoading, seasons, create, update, cancel }}>
      {children}
    </Seasons.Provider>
  );
};

export { Seasons, SeasonsProvider };
