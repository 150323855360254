import React, { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { fetchDepartments, createDepartment, updateDepartment, deleteDepartment } from '../api/departments';

const Departments = React.createContext();

const DepartmentsProvider = ({ children }) => {
  const { authenticated } = useAuth();

  const [isLoading, setIsLoading] = useState();
  const [departments, setDepartments] = useState([]);

  const create = data => createDepartment(data).then(department => setDepartments(prev => [...prev, department]));
  const update = (code, data) => updateDepartment(code, data).then(department => setDepartments(prev => prev.map(e => e.code === code ? department : e)));
  const cancel = code => deleteDepartment(code).then(() => setDepartments(prev => prev.filter(e => e.code !== code)));

  useEffect(() => {
    if (authenticated && isLoading === undefined) {
      setIsLoading(true);
      fetchDepartments().then(data => {
        setDepartments(data);
        setIsLoading(false);
      });
    }
  }, [authenticated, isLoading]);

  return (
    <Departments.Provider value={{ isLoading, departments, create, update, cancel }}>
      {children}
    </Departments.Provider>
  );
};

export { Departments, DepartmentsProvider };
