import React, { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { fetchStockList, createStock, updateStock, deleteStock } from '../api/stocks';

const Stocks = React.createContext();

const StocksProvider = ({ children }) => {
  const { authenticated } = useAuth();

  const [isLoading, setIsLoading] = useState();
  const [stocks, setStocks] = useState([]);

  const create = data => createStock(data).then(stock => setStocks(prev => [...prev, stock]));
  const update = (id, data) => updateStock(id, data).then(stock => setStocks(prev => prev.map(e => e.id === id ? stock : e)));
  const cancel = id => deleteStock(id).then(() => setStocks(prev => prev.filter(e => e.id !== id)));

  useEffect(() => {
    if (authenticated && isLoading === undefined) {
      setIsLoading(true);
      fetchStockList().then(data => {
        setStocks(data);
        setIsLoading(false);
      });
    }
  }, [authenticated, isLoading]);

  return <Stocks.Provider value={{ isLoading, stocks, create, update, cancel }}>{children}</Stocks.Provider>;
};

export { Stocks, StocksProvider };
