import axios from 'axios';
import { API_URL } from '../config';

export const fetchBrands = async () => {
  const response = await axios.get(`${API_URL}/brands`);
  return response.data;
};

export const createBrand = async data => {
  const response = await axios.post(`${API_URL}/brands`, data);
  return response.data;
};

export const updateBrand = async (id, data) => {
  const response = await axios.post(`${API_URL}/brands/${id}`, data);
  return response.data;
};

export const deleteBrand = async id => {
  await axios.delete(`${API_URL}/brands/${id}`);
};
