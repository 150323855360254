import { useContext } from 'react';
import { Auth } from '../contexts/Auth';

const useAuth = () => {
  const { authenticated, user, onLogin, onLogout } = useContext(Auth);


  return { authenticated, user, onLogin, onLogout };
};

export default useAuth;
