import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './index.css';
import Providers from './Providers';

const LazyApp = lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Providers>
        <LazyApp />
        <Toaster position='bottom-center' toastOptions={{ className: 'react-hot-toast' }} />
      </Providers>
    </BrowserRouter>
  </React.StrictMode>
);
