import React, { useMemo, useState } from 'react';

const Auth = React.createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  const authenticated = useMemo(() => !!user, [user]);

  const onLogin = user => {
    localStorage.setItem('user', JSON.stringify(user));
    setUser(user);
  };

  const onLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  return (
    <Auth.Provider value={{ authenticated, user, onLogin, onLogout }}>
      {children}
    </Auth.Provider>
  );
};

export { Auth, AuthProvider };
