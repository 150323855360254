import axios from 'axios';
import { API_URL } from '../config';

export const fetchSeasons = async () => {
  const response = await axios.get(`${API_URL}/seasons`);
  return response.data;
};

export const createSeason = async data => {
  const response = await axios.post(`${API_URL}/seasons`, data);
  return response.data;
};

export const updateSeason = async (id, data) => {
  const response = await axios.post(`${API_URL}/seasons/${id}`, data);
  return response.data;
};

export const deleteSeason = async id => {
  await axios.delete(`${API_URL}/seasons/${id}`);
};
