import React from 'react';
import { AuthProvider } from './contexts/Auth';
import { BrandsProvider } from './contexts/Brands';
import { CategoriesProvider } from './contexts/Categories';
import { DepartmentsProvider } from './contexts/Departments';
import { ModalProvider } from './contexts/Modal';
import { SeasonsProvider } from './contexts/Seasons';
import { StocksProvider } from './contexts/Stocks';

const Providers = ({ children }) => (
  <AuthProvider>
    <BrandsProvider>
      <CategoriesProvider>
        <DepartmentsProvider>
          <SeasonsProvider>
            <StocksProvider>
              <ModalProvider>
                {children}
              </ModalProvider>
            </StocksProvider>
          </SeasonsProvider>
        </DepartmentsProvider>
      </CategoriesProvider>
    </BrandsProvider>
  </AuthProvider>
);

export default Providers;
