import React, { createContext, useCallback, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ModalContext = createContext();

class Wrapper {
  constructor (promise) {
    promise.then(result => {
      if (result) {
        this.confirmCb?.();
      } else {
        this.cancelCb?.();
      }
    });
  }

  onConfirm (cb) {
    this.confirmCb = cb;
    return this;
  }

  onCancel (cb) {
    this.cancelCb = cb;
    return this;
  }
}

const ModalProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [confirmButton, setConfirmButton] = useState();
  const [cancelButton, setCancelButton] = useState();
  const resolver = useRef();

  const openModal = useCallback(({ title, body, confirmButton, cancelButton }) => {
    setTitle(title);
    setBody(body);
    setConfirmButton(confirmButton);
    setCancelButton(cancelButton);
    setShow(true);
    return new Wrapper(new Promise(resolve => resolver.current = resolve));
  }, []);

  const closeModal = useCallback(() => setShow(false), []);

  const onConfirm = () => {
    resolver.current(true);
    setShow(false);
  };

  const onCancel = () => {
    resolver.current(false);
    setShow(false);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Modal show={show} onHide={closeModal} centered scrollable>
        {title && (
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}
        {body && <Modal.Body>{body}</Modal.Body>}
        {(cancelButton || confirmButton) && (
          <Modal.Footer>
            {cancelButton && <Button variant={cancelButton.color || 'secondary'} onClick={onCancel}>{cancelButton.text}</Button>}
            {confirmButton && <Button variant={confirmButton.color || 'primary'} onClick={onConfirm}>{confirmButton.text}</Button>}
          </Modal.Footer>
        )}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext as Modal, ModalProvider };
